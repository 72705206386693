<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>

            <div class="filters-row df">
                <h2>{{ $t('rewardsHeading')}}</h2>
              </div>
            <b-card-group>
              <b-card no-body>
                <b-card-header class="p-1 pl-3" style="text-align: left;">
                  1. {{ $t('referralCode.whatIsTitle') }}
                </b-card-header>
                <b-collapse id="accordion-referral-description" visible>
                  <b-card-body>
                    <b-row>
                      <div
                        class="form-group col-12"
                        style="text-align: left;"
                      >
                        <label>{{ $t('copyReferralCode') }}</label>
                      </div></b-row
                    >
                    <b-row class="pb-3">
                      <div
                        class="form-group col-12"
                        style="text-align: left;"
                      >
                        <b-button
                          id="referralCodeButton"
                          v-clipboard:copy="referralText"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          variant="outline-info"
                          >{{ referralCode }}
                        </b-button>
                      </div>
                    </b-row>
                    <b-row>
                      <div
                        class="form-group col-12"
                        style="text-align: left;"
                      >
                        <label>{{ $t('referralCodeDescription') }}</label>
                      </div>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body>
                <b-card-header class="p-1 pl-3" style="text-align: left;">
                  2. {{ $t('referralCode.usageTitle') }}
                </b-card-header>
                <b-collapse id="accordion-referral-code-guide" visible>
                  <b-card-body>
                    <b-row>
                      <div
                        class="form-group col-12"
                        style="text-align: left;"
                      >
                        <label>{{ $t('referralCode.usageText') }}</label>
                      </div>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body>
                <b-card-header class="p-1 pl-3" style="text-align: left;">
                  3. {{ $t('referralCode.benefitsTitle') }}
                </b-card-header>
                <b-collapse id="accordion-referral-code-benefits" visible>
                  <b-card-body>
                    <b-row>
                      <div
                        class="form-group col-12"
                        style="text-align: left;"
                      >
                        <label>{{ $t('referralCode.benefitsText') }}</label>
                      </div>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-card-group>
            <div class="mt-4">
              <small>{{ $t('referralCode.benefitsCondition') }}</small>
            </div>
    
      
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import OkkuApi from '@/services/OkkuApi'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

const referralLinkPrefix =
  'https://www.okku.io/reserveringssysteem-werkplekken/'
export default {
  name: 'TodayScreens',
  components: {},
  data() {
    return {
      referralLink: referralLinkPrefix,
      referralCode: '',
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      loading: true
    }
  },
  computed: {
    copyReferralLinkLabel() {
      return this.$t('copyReferralCode')
    }
  },
  async mounted() {
    await this.getReferralCode()
    this.loading = false
  },
  methods: {
    async getReferralCode() {
      const referralCode = await OkkuApi.getReferralCode()
      this.referralCode = referralCode
      this.referralText = this.$t('referralText', {
        code: referralCode
      })
    },
    onCopy() {
      this.updateAlert({
        variant: 'success',
        text: this.$t('referralCodeCopied')
      })
    },
    onError() {
      this.updateAlert({
        variant: 'danger',
        text: this.$t('referralCodeNotCopied')
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    }
  }
}
</script>
<style lang="scss" scoped>
#referralCodeButton {
  width: 200px;
}
</style>
